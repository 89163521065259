import { createArrow, growArrow, images, imagineArrow } from "../images";
import _ from "lodash";

export const categories = [
  {
    id: 1,
    title: "community voting",
    subTitle: "imagine award",
    // desc: `IMAGINE finalists are pre-launch companies that are in the
    // process of finalizing their businesses and brands. Please click
    // through the image carousel for each finalist to view their full
    // company video`,
    desc: `
    Each of our talented finalists have shared a video introducing their 
    business and why they should win.  Please choose one finalist from 
    each category and vote.`,
    slug: "imagine",
    templateColor: "#ff6b20",
    arrowColor: imagineArrow,
  },
  {
    id: 2,
    title: "community voting",
    subTitle: "Grow award",
    // desc: `GROW finalists are pre-launch companies that are in the
    // process of finalizing their businesses and brands. Please click
    // through the image carousel for each finalist to view their full
    // company video`,
    desc: `Each of our talented finalists have shared a video introducing their
     business and why they should win.  Please choose one finalist from each category
     and vote.`,
    slug: "grow",
    templateColor: "#9fa6d4",
    arrowColor: growArrow,
  },
  // {
  //   id: 3,
  //   title: "community voting",
  //   subTitle: "Create award",
  //   desc: `CREATE finalists are pre-launch companies that are in the
  //   process of finalizing their businesses and brands. Please click
  //   through the image carousel for each finalist to view their full
  //   company video`,
  //   slug: "create",
  //   templateColor: "#fdbc00",
  //   arrowColor: createArrow,
  // },
];

export const datas = [
  {
    category_id: 1,
    category: "create",
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vimagine1.image,
    alt: images.vimagine1.alt,
    heroImg: images.imaginevoting1.image,

    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 2,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 3,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.imagine3.image,
    alt: images.imagine3.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 4,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.imagine4.image,
    alt: images.imagine4.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 5,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.imagine5.image,
    alt: images.imagine5.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },

  {
    id: 6,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vgrow1.image,
    alt: images.vgrow1.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 7,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vgrow2.image,
    alt: images.vgrow2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 8,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vgrow3.image,
    alt: images.vgrow3.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 9,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vgrow4.image,
    alt: images.vgrow4.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 10,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vgrow5.image,
    alt: images.vgrow5.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },

  {
    id: 11,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vcreate1.image,
    alt: images.vcreate1.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 12,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vcreate2.image,
    alt: images.vcreate2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 13,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vcreate3.image,
    alt: images.vcreate3.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 14,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vcreate4.image,
    alt: images.vcreate4.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 15,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    img: images.vcreate5.image,
    alt: images.vcreate5.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
];
// dynamic data end

export const findAll = (category_slug = null) => {
  if (category_slug) {
    // return _.find(datas, { 'category': category_slug });
    return _.filter(datas, { category: category_slug });
  } else {
    return datas;
  }
};

export const findBySlug = (slug) => {
  let data = _.find(datas, { slug: slug });
  let category = findCategoryBySlug(data.category);
  return { ...data, categoryData: category };
};
export const findCategoryBySlug = (slug) => {
  return _.find(categories, { slug: slug });
};
