import React, { useEffect } from "react";
import "./applicationcriteria.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { images } from "../../images";
import { useWindowSize } from "react-use";
import { Link } from "react-router-dom";
import { accountRegister, faqURL } from "../../helpers/constant-words";

const ApplicationCriteria = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <main>
        <CommonBanner
          imgSrc={
            width > 600
              ? images.applicationCriteriaBanner.image
              : images.mbApplicationCriteriaBanner.image
          }
          title={`APPLICATION ${width < 600 ? "<br />" : ""}CRITERIA`}
        />
        <div className="my_container full_width_container zero_padding">
          <section className="app_sec1">
            <h1 className="section_subheading text-center">
              APPLICATIONS TO THE BEAUTY&YOU India 2024 <br />
              PROGRAM ARE NOW CLOSED.
            </h1>
          </section>

          <section className="application_box   app_sec2">
            <div className="row g-0">
              <div className="col-md-6 col-lg-7 p-0">
                <div className="text_container">
                  <h3 className="section_subheading">APPLICATION CRITERIA</h3>
                  <p className="app_desc">
                    APPLICANTS TO THE BEAUTY&YOU INDIA 2024 PROGRAM MUST BE 18
                    YEARS OF AGE OR OLDER AS OF DECEMBER 31, 2023
                  </p>
                  {/* <ol className="app_desc">
                    <li className="ol_list_item">
                      APPLICANTS TO THE BEAUTY&YOU INDIA 2024 PROGRAM MUST BE 18
                      YEARS OF AGE OR OLDER AS OF DECEMBER 31
                      <sup style={{ textTransform: "lowercase" }}>st</sup> 2023.
                    </li>
                    <br />
                    <br />
                    <li className="ol_list_item">
                      There are three application categories:
                    </li>
                  </ol> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-5 p-0">
                <div className="app_cat_box app_sub_title">
                  <h4 className="cat_head">three application categories</h4>
                </div>
                <div className="app_cat_box">
                  <h4 className="cat_head">IMAGINE</h4>
                  <p className="section_desc">
                    Companies that are pre-launch and pre-revenue with founders
                    seeking initial launch funding.
                  </p>
                </div>
                <div className="app_cat_box">
                  <h4 className="cat_head">GROW</h4>
                  <p className="section_desc">
                    Companies currently trading in-market with some revenue with
                    founders seeking growth capital. Applicants must be
                    revenue-generating companies with at least one point of
                    distribution - company website is sufficient.
                  </p>
                </div>
                <div className="app_cat_box">
                  <h4 className="cat_head">CREATE</h4>
                  <p className="section_desc">
                    Photographers, Filmmakers, Make Up Artists and Hair Stylists
                    submitting work that focuses on their interpretation of
                    Supercharged Futures. Applicants may be based anywhere in the world
                    but must submit an application that is aligned with the
                    theme.
                  </p>
                </div>
                <div className="app_notice">
                  <p className="section_desc">
                    All applications should be in the beauty category and can
                    fall under any one or more of the following subcategories:
                    Makeup, skin care, hair care, fragrance and home fragrances.{" "}
                    <br />
                    <br />
                    Founders and companies based outside of India are welcome to
                    apply as long as their concept or plan is focused on India
                    or created for the Indian consumer needs.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="application_box app_sec3">
            <div className="row g-0">
              <div className="col-md-6 col-lg-7 p-0">
                <div className="text_container">
                  <h3 className="section_subheading">
                    ITEMS YOU WILL NEED TO COMPLETE THE APPLICATION:
                  </h3>
                  <p className="app_desc">*may vary depending on category*</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 p-0">
                <ol className="app_notice">
                  <li className="section_desc ol_list_item">
                    Company details, including your India Incorporation Number
                    (if applicable) and links to your social channels.
                  </li>
                  <li className="section_desc ol_list_item">
                    Executive summary or submission about your business, idea or
                    creative work. Please make sure you cover the following
                    points in your summary:
                    <ul className="app_list">
                      <li className="list_item">Market Opportunity</li>
                      <li className="list_item">Problem and Solution</li>
                      <li className="list_item">Brand and Product Idea</li>
                      <li className="list_item">Distribution Plan</li>
                    </ul>
                  </li>
                  <li className="section_desc ol_list_item">
                    Why your company should win.
                  </li>
                  <li className="section_desc ol_list_item">
                    How your company or idea connects beauty to the broader
                    world.
                  </li>
                  <li className="section_desc ol_list_item">
                    Any video assets that support your application.
                  </li>
                </ol>
              </div>
            </div>
          </section>

          <section className="app_sec4">
            <h5 className="section_subheading text-center">any questions?</h5>
            <p className="section_desc text-center">
              Check out our <Link to={faqURL}>FAQs</Link> section or email us at{" "}
              <a className="anchor" href="mailto:hello@beautyandyouindia.com">
                hello@beautyandyouindia.com
              </a>
            </p>
            {/* <div className="cta_wrapper">
              <Link to={accountRegister} className="project-button">
                APPLY NOW
              </Link>
            </div> */}
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ApplicationCriteria;
