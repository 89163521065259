import React, { useEffect, useState } from "react";
import "./faq.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FaqItem from "../../components/Faqitem/FaqItem";

const faqData = [
  {
    faqTitle: "WHO IS ELIGIBLE TO APPLY?",
    faqDesc: `<ul> <li>Applicants to the BEAUTY&YOU India Program 2024 must be 18 years of age or older as of December 31st 2023.</li> 
    <li>There are three application categories:
    <ul class="disc_style">
    <li>IMAGINE: Companies that are pre-launch and pre-revenue with founders seeking initial launch funding,</li>
    <li>GROW: Companies currently trading in-market with some revenue and founders seeking growth capital (Applicants must be revenue-generating companies with at least one point of distribution - company website is sufficient)</li>
    <li>CREATE: Photographers, Filmmakers, Make Up Artists, Hair Stylists submitting work that focuses on Supercharged Futures. All applications should be in the beauty category and can fall under any one or more of the following subcategories: Makeup, skin care, Body Care, hair care, fragrance and home fragrances. Founders and companies based outside of India are welcome to apply as long as their concept or plan is focused on India or created for the Indian consumer needs.</li></ul></li></ul>`,
  },
  {
    faqTitle:
      "I’M AN INDIAN ENTREPRENEUR WITH A BEAUTY BUSINESS, BUT LIVE AND OPERATE OUTSIDE OF INDIA. AM I ELIGIBLE TO APPLY?",
    faqDesc: `<div class="faq_para">Yes, we are accepting applications globally for all India-focused ideas and businesses. See the <a href="/application-criteria">Application Criteria</a> for more details. </div>`,
  },
  {
    faqTitle: "WHO ARE THE JUDGES?",
    faqDesc: `<div class="faq_para">Judges include representatives from The Estée Lauder Companies alongside a group of accomplished entrepreneurs, investors, content creators and experts in the category. To see the fully panel of judges, please visit the 
      <a href="/judges">Judges page</a>.</div>`,
  },
  {
    faqTitle: "WHAT’S THE SELECTION CRITERIA?",
    faqDesc:
      "<ul><li>Compelling idea or concept for a premium beauty brand.</li> <li> Team</li> <li>Innovative business model </li> <li>Breakthrough product innovation </li> <li>Contemporary distribution strategy </li> <li>CSR mission </li> <li>Creative approach to content, storytelling and customer acquisition.</li></ul>",
  },
  {
    faqTitle: "HOW DO YOU DEFINE A PREMIUM BEAUTY BRAND?",
    faqDesc: `<div class="faq_para">'Premium' is more than just the price of products and manifests itself differently across geographies and as consumers and channels evolve. We look for beauty brands that elevate the story, the packaging, the products and the entire brand experience to create a compelling brand proposition for consumers.</div>`,
  },
  {
    faqTitle: "WHAT CAN I DO WITH THE AWARD MONEY?",
    faqDesc: `<div class="faq_para">Award money must be used to further the development of the idea or company.</div>`,
  },
  {
    faqTitle: "I HAVE AN IDEA BUT HAVEN'T LAUNCHED YET, AM I ELIGIBLE?",
    faqDesc: `<div class="faq_para">Yes! Judges will evaluate your business within our IMAGINE category.</div>`,
  },
  {
    faqTitle: "WHAT’S THE DEADLINE FOR APPLICATIONS?",
    faqDesc: `<div class="faq_para">SEPTEMBER 5<sup>th</sup> 2024 11:59 PM IST</div>`,
  },
  {
    faqTitle: "I HAVE MORE QUESTIONS! WHO CAN I CONTACT?",
    faqDesc: `<div class="faq_para">Please reach out to us at <a href="mailto:hello@beautyandyouawards.com">hello@beautyandyouawards.com</a>.</div>`,
  },
];

const Faq = () => {
  const [faqActiveIndex, setFaqActiveIndex] = useState(null);

  const handleFaqIndexClick = (i) => {
    setFaqActiveIndex(i);
  };

  const faqList = faqData.map((faq, i) => (
    <FaqItem
      faqTitle={faq.faqTitle}
      faqDesc={faq.faqDesc}
      key={i}
      faqIndex={i + 1}
      handleFaqIndexClick={handleFaqIndexClick}
      faqActiveIndex={faqActiveIndex}
    />
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header headerFixed={true} />
      <div className="my_container zero_padding full_width_container">
        <section className="faq_sec1">
          <h3 className="section_subheading">FAQ</h3>

          <div className="faq_list_box">
            <ul className="faq_list">{faqList}</ul>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
