import { images } from "../images";
const embed = "https://www.youtube.com/watch?v=";

export const winnerData = [
  {
    id: 1,
    image: images.meetwinner3.image,
    alt: images.meetwinner3.alt,
    category: "BREAKTHROUGH <br /> AWARD WINNER",
    brand: "POSHAN SKINCARE",
    name: "Rhea Goenka",
    desc: `Poshan Skincare is a purpose-driven skincare company championing Indian skin inclusivity led by 
    scientist, Rhea Goenka Jalan and with a firm belief that biology is the most advanced technology on the 
    planet. Their approach blends the rich tapestry of Indian culture with the boundless ability of bacterial
     cultures. Through locally sourced ingredients, green science and biotechnology, Poshan Skincare fosters 
     sustainable product development. Their "Food for Thought™" collection celebrates Indian cuisine by 
     uniting traditional superfoods with a proprietary probiotic blend that taps into the skin microbiome's 
     potential. The company's mission is to provide nourishing solutions for Indian skin while inspiring a 
     reevaluation of unhealthy beauty standards.`,
    watch: embed + "xEDFHcFe244",
    web: "",
    insta: "https://www.instagram.com/poshanskincare/",
    ctaClass: "blue",
    color: "#29afce",
    scrollImg: images.scrollImgBreak.image,
    scrollalt: images.scrollImgBreak.alt,
    sliderImgClasss: "meet_slider_breakthrough",
  },
  {
    id: 2,
    image: images.meetwinner4.image,
    alt: images.meetwinner4.alt,
    category: "BREAKTHROUGH <br /> AWARD WINNER",
    brand: "KEVA FRAGRANCES <br/> FLAVOURS & AROMA INGREDIENTS",
    name: "Dr. Avani Mainkar and Kedar Vaze",
    desc: `S H Kelkar and Company Limited (Keva) is a manufacturer of industrial perfumes and one of India's 
    largest fragrance companies with an ever expanding global presence. With a significant presence in 
    fragrance, flavours and aroma ingredients, Keva has now ventured into natural actives for cosmetics.`,
    watch: embed + "q5CYw43D9F8",
    web: "https://keva.co.in",
    insta:
      "https://www.instagram.com/kevafragrances.global/?igshid=YmMyMTA2M2Y%3D",
    ctaClass: "blue",
    color: "#29afce",
    scrollImg: images.scrollImgBreak.image,
    scrollalt: images.scrollImgBreak.alt,
    sliderImgClasss: "meet_slider_breakthrough",
  },

  {
    id: 3,
    image: images.meetwinner8.image,
    alt: images.meetwinner8.alt,
    category: "COMMUNITY <br /> AWARD WINNER",
    brand: "BEAUTYWISE",
    name: "Anousha Chauhan and Shreyansh Chauhan",
    desc: `Anousha and Shreyansh Chauhan, brother-sister entrepreneurs driving the aspirations of Beautywise, 
    a dermatology-led professional beauty supplementation brand with the revolutionary Dual-action technology 
    (Powered by V-FillTM) that enables to combine oil soluble and water soluble ingredients in one innovative 
    capsule for enhanced bio- availability and better efficacy.
    <br/> <br/>
    Our mission is to empower everyone to embrace their unique beauty from within, by incorporating sustainable,
     evidence-based beauty supplements for long lasting results.
    <br/>  <br/>
    At Beautywise, we've forged strong partnerships with industry leaders. Our footprint extends Pan India 
    in the retail landscape, with presence in over 500 pharmacies and Dermatology clinics . Our journey online 
    has been nothing short of remarkable, in a short duration we have made an impressive impact online , and 
    are one of the top selling brands on Nykaa and Amazon.`,
    watch: embed + "KYpKSsaknOw",
    web: "https://beautywise.in/",
    insta: "https://www.instagram.com/beautywiseskinfood/",
    ctaClass: "green",
    color: "#6da991",
    scrollImg: images.scrollImgCommunity.image,
    scrollalt: images.scrollImgCommunity.alt,
    sliderImgClasss: "meet_slider_create",
  },
  {
    id: 4,
    image: images.meetwinner9.image,
    alt: images.meetwinner9.alt,
    category: "COMMUNITY <br /> AWARD WINNER",
    brand: "HAVAH",
    name: "Giti Datt",
    desc: `Havah draws from India’s rich heritage of perfumery, art and philosophy, to develop a unique 
    perspective on the power of fragrance in contemporary life. Crafted for the curious and connoisseurs alike,
     our perfumes are an immersive sensory experience, designed to evoke emotions and make moods. Embedded in 
     community, Havah both honours the deep, embodied knowledge of attar artisans whilst experimenting with 
     innovation and evolution and marrying the best of precious, natural aromatics with modern sensibilities 
     and ethical practices. In essence, Havah conceptualises a distinctly modern, Indian aesthetic of perfumery,
      building upon our rich legacy in new ways.`,
    watch: embed + "qYdy9DF5GaE",
    web: "",
    insta: "https://www.instagram.com/gitidatt/?hl=de",
    ctaClass: "green",
    color: "#6da991",
    scrollImg: images.scrollImgCommunity.image,
    scrollalt: images.scrollImgCommunity.alt,
    sliderImgClasss: "meet_slider_create",
  },

  {
    id: 5,
    image: images.meetwinner5.image,
    alt: images.meetwinner5.alt,
    category: "CREATE <br /> AWARD WINNER",
    brand: "PRIYA MINHAS",
    name: "Director",
    desc: `Priya Minhas is a South Asian Director from London who has created culturally impactful live 
    performances, music videos, and short-form documentaries with the most exciting names in music. 
    <br/> <br/>
    Priya is passionate about telling stories that emerge from the margins and exploring diasporic identities. 
    In 2020, she was one of six directors selected for The Salon, a mentorship program for South Asian talent 
    in Hollywood. In 2021, she won the audience choice award for "Best Music Video" at the South Asian Film 
    Festival of America. She was also a contributing writer to The Good Immigrant, USA, an award-winning 
    anthology exploring race.`,
    watch: embed + "I3bGt9oVzmg",
    web: "https://www.priyaminhas.com",
    insta: "https://www.instagram.com/pri_diddy/",
    ctaClass: "yellow",
    color: "#fdbc00",
    scrollImg: images.scrollImgCreate.image,
    scrollalt: images.scrollImgCreate.alt,
  },

  {
    id: 6,
    image: images.meetwinner1.image,
    alt: images.meetwinner1.alt,
    category: "GROW <br /> AWARD WINNER",
    brand: "CALL OF THE VALLEY",
    name: "Jean-Christophe Bonnafous",
    desc: `After a decade-long career as flutist and disciple of the revered classical flutist Pandit Hariprasad
    Chaurasia, Jean-Christophe Bonnafous launched the Mumbai-based perfumery Call of the Valley in 2019. 
    Inspired by the idea of simple beauty with a focus on ingredients that are sustainably sourced from across 
    India to ensure maximum potency, Jean-Christophe’s vision for the company is to bring single-ingredient 
    simplicity back to beauty and highlight traditional Indian wellness practices and the centuries-old art of
    Indian perfumery on a global stage.`,
    watch: embed + "4KoFVHjUIUM",
    web: "https://callofthevalley.co.in/",
    insta: "https://www.instagram.com/callofthevalleyofficial/",
    ctaClass: "project-button purple",
    color: "#9fa6d4",
    scrollImg: images.scrollImgGrow.image,
    scrollalt: images.scrollImgGrow.alt,
  },

  {
    id: 7,
    image: images.meetwinner2.image,
    alt: images.meetwinner2.alt,
    category: "IMAGINE <br /> AWARD WINNER",
    brand: "BABOSKI",
    name: "Hetali Kamdar",
    desc: `BABOSKI stands as India's premier luxury bath and body products brand, meticulously curated using 
    top-tier ingredients, including natural extracts and EncapShield SPF technology. Pioneering innovative 
    sun care solutions, we proudly introduce India's first Bathe-On Sunscreen range, seamlessly integrating 
    into your daily routine. Our aim is to provide the range on Bathe-On Sunscreen with fragrances that 
    elevate everyday rituals into sublime sensory experiences. Beyond our commitment to quality, we aspire
     to champion mental health awareness. BABOSKI's dedication lies in enhancing well-being and confidence, 
     empowering individuals to embrace holistic self-care and beauty in every facet of life.`,
    watch: embed + "a9soYDh_s3c",
    web: "https://baboski.com/",
    insta: "https://www.instagram.com/baboski.official/",
    ctaClass: "",
    color: "#ff6b20",
    scrollImg: images.scrollImgImagine.image,
    scrollalt: images.scrollImgImagine.alt,
  },

  {
    id: 8,
    image: images.meetwinner7.image,
    alt: images.meetwinner7.alt,
    category: "IMPACT <br /> AWARD WINNER",
    brand: "SOHRAI",
    name: "Rupesh Pawar and Khanak Gupta",
    desc: `Sohrai is a revolutionary beauty brand that transcends skincare, ignites change and enriches lives. 
     In India, tribal communities face intergenerational poverty, surviving on meager incomes. The revered mahua
     tree symbolizes their way of life, yet its untapped potential persists. Sohrai bridge this gap by 
     empowering tribal women with premium wages through the collection of mahua seeds. Through innovative 
     enzymatic-interesterification, the brand crafts mahua butter - a natural skin elixir. Their luxury line, 
     from lipsticks to body butters, champions sustainability with eco-conscious packaging and tribal art. 
     Sohrai isn't just a beauty brand, it's a celebration of heritage, transforming cosmetics into tools of 
     empowerment.`,
    watch: embed + "hVQPeJnvwGA",
    web: "",
    insta: "https://www.instagram.com/sohraibeauty/?hl=en",
    ctaClass: "brown",
    color: "#c6557e",
    scrollImg: images.scrollImgImpact.image,
    scrollalt: images.scrollImgImpact.alt,
  },
  {
    id: 9,
    image: images.meetwinner6.image,
    alt: images.meetwinner6.alt,
    category: "IMPACT <br /> AWARD WINNER",
    brand: "C16 BIOSCIENCES",
    name: "Shara Ticku",
    desc: `Shara Ticku is the CEO and Co-Founder of C16 Biosciences, the makers of Palmless™: the platform for 
    sustainable palm oil alternatives. C16’s proprietary technology platform uses biomanufacturing to create 
    next-generation ingredients and materials that successfully address global ESG challenges—including climate
    change, food security, the environmental and safety impacts of natural resource extraction, and more.`,
    watch: embed + "2n1lmJaglcc",
    web: "https://www.c16bio.com",
    insta: "https://www.instagram.com/gopalmless/",
    ctaClass: "brown",
    color: "#c6557e",
    scrollImg: images.scrollImgImpact.image,
    scrollalt: images.scrollImgImpact.alt,
  },
];
