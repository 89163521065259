import {
  breakURL,
  createURL,
  growURL,
  imagineURL,
  votingURL,
  votingcreateURL,
  votinggrowURL,
  votingimagineURL,
} from "../helpers/constant-words";
import { createArrow, growArrow, images, imagineArrow } from "../images";
import _ from "lodash";

export const votingImagineData = [
  {
    id: 1,
    img: images.vimagine1.image,
    mbalt: images.vimagine1.alt,
    mbimg: images.mbvimagine1.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingimagineURL,
    // watch: "javascript:void(0)",
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
  },
  {
    id: 2,
    img: images.vimagine2.image,
    alt: images.vimagine2.alt,
    mbimg: images.mbvimagine2.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingimagineURL,
    watch: "https://www.youtube.com/embed/wFDMrCAy-YA",
  },
  {
    id: 3,
    img: images.vimagine3.image,
    alt: images.vimagine3.alt,
    mbimg: images.mbvimagine3.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingimagineURL,
    watch:
      "https://www.youtube.com/watch?v=mAA5Za-nHPc&ab_channel=BharatiyaDigitalParty",
  },
  {
    id: 4,
    img: images.vimagine4.image,
    alt: images.vimagine4.alt,
    mbimg: images.mbvimagine4.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingimagineURL,
    watch: "https://www.youtube.com/embed/RFW_et6Cfn4",
  },
  {
    id: 5,
    img: images.vimagine5.image,
    alt: images.vimagine5.alt,
    mbimg: images.mbvimagine5.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingimagineURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
];

export const votingGrowData = [
  {
    id: 1,
    img: images.vgrow1.image,
    alt: images.vgrow1.alt,
    mbimg: images.mbvgrow1.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votinggrowURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 2,
    img: images.vgrow2.image,
    alt: images.vgrow2.alt,
    mbimg: images.mbvgrow2.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votinggrowURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 3,
    img: images.vgrow3.image,
    alt: images.vgrow3.alt,
    mbimg: images.mbvgrow3.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votinggrowURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 4,
    img: images.vgrow4.image,
    alt: images.vgrow4.alt,
    mbimg: images.mbvgrow4.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votinggrowURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 5,
    img: images.vgrow5.image,
    alt: images.vgrow5.alt,
    mbimg: images.mbvgrow5.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votinggrowURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
];

export const votingCreateData = [
  {
    id: 1,
    img: images.vcreate1.image,
    alt: images.vcreate1.alt,
    mbimg: images.mbvcreate1.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingcreateURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 2,
    img: images.vcreate2.image,
    alt: images.vcreate2.alt,
    mbimg: images.mbvcreate2.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingcreateURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 3,
    img: images.vcreate3.image,
    alt: images.vcreate3.alt,
    mbimg: images.mbvcreate3.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingcreateURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 4,
    img: images.vcreate4.image,
    alt: images.vcreate4.alt,
    mbimg: images.mbvcreate4.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingcreateURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
  {
    id: 5,
    img: images.vcreate5.image,
    alt: images.vcreate5.alt,
    mbimg: images.mbvcreate5.image,
    name: "Katrina Kaif",
    desg: "FOUNDER & CEO | SABYASACHI",
    info: votingcreateURL,
    watch: "https://www.youtube.com/watch?v=dvTSij4Mlfo",
  },
];

export const vImagineFinalistData = [
  {
    id: 1,
    img: images.imaginevoting1.image,
    alt: images.imaginevoting1.alt,
    name: "finalist name",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s
      number one actress and has a knack for the number game. The
      superstar-turned-entrepreneurial genius, Katrina Kaif established
      her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained
      popularity as one of India’s most loved make-up lines making her the
      first ever Bollywood celebrity to launch their own beauty line. With
      her own line, she believes the aim with Kay Beauty is to create a
      diverse and inclusive brand that would resonate with makeup lovers
      and specifically cater to Asian skin requirements. Katrina has won
      the “Beauty Entrepreneur of the Year award for the same.`,
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
];

export const vGrowFinalistData = [
  {
    id: 1,
    img: images.growvoting1.image,
    alt: images.growvoting1.alt,
    name: "finalist name",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
       and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
       established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
       one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
       launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
        create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
        cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
        for the same.`,
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
];

export const vCreateFinalistData = [
  {
    id: 1,
    img: images.createvoting1.image,
    alt: images.createvoting1.alt,
    name: "finalist name",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
       and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
       established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
       one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
       launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
        create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
        cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
        for the same.`,
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
];

// dynamic data start
export const votingCategoriesArr = [
  {
    id: 1,
    title: "community voting",
    subTitle: "Grow award",
    desc: `IMAGINE finalists are pre-launch companies that are in the
    process of finalizing their businesses and brands. Please click
    through the image carousel for each finalist to view their full
    company video`,
    slug: "grow",
    templateColor: "#9fa6d4",
    arrowColor: growArrow,
  },
  {
    id: 2,
    title: "community voting",
    subTitle: "imagine award",
    desc: `IMAGINE finalists are pre-launch companies that are in the
    process of finalizing their businesses and brands. Please click
    through the image carousel for each finalist to view their full
    company video`,
    slug: "imagine",
    templateColor: "#ff6b20",
    arrowColor: imagineArrow,
  },
  {
    id: 3,
    title: "community voting",
    subTitle: "Create award",
    desc: `IMAGINE finalists are pre-launch companies that are in the
    process of finalizing their businesses and brands. Please click
    through the image carousel for each finalist to view their full
    company video`,
    slug: "create",
    templateColor: "#fdbc00",
    arrowColor: createArrow,
  },
];

export const votingArr = [
  {
    id: 1,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 2,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 3,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine3.image,
    alt: images.imagine3.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 4,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine4.image,
    alt: images.imagine4.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 5,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine5.image,
    alt: images.imagine5.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },

  {
    id: 6,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 7,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 8,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 9,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 10,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },

  {
    id: 11,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 12,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 13,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 14,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 15,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 16,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 17,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 18,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
];
// dynamic data end

export const findAllVoting = (category_slug = null) => {
  if (category_slug) {
    const category = findCategoryWhere({ slug: category_slug });
    return _.filter(votingArr, { category_id: category[0]?.id });
  } else {
    return votingArr;
  }
};

export const getHeaderVoting = (category_id = null) => {
  const category = findCategoryWhere({ id: category_id });
  return _.filter(votingArr, { category_id: category_id }).map((item) => {
    return {
      title: item.title,
      slug: `/community-voting/${category?.[0]?.slug}/${item.slug}`,
    };
  });
};

export const findVotingBySlug = (slug) => {
  return _.find(votingArr, { slug: slug });
};

export const findCategoryBySlug = (slug) => {
  return _.find(votingCategoriesArr, { slug: slug });
};

export const findCategoryWhere = (filter = {}) => {
  return _.filter(votingCategoriesArr, filter);
};
export const findVotingWhere = (filter = {}) => {
  return _.filter(votingArr, filter);
};
