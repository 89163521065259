import React, { useEffect } from "react";
import "./contactus.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { insta, linke } from "../../images";
import { instaLink, linkedinLink } from "../../helpers/constant-words";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header headerFixed={true} />
      <div className="my_container zero_padding">
        <section className="contactsec">
          <h1 className="section_subheading">Contact Us</h1>
          <div className="row mail_box_wrapper">
            {/* <div className="col-lg-6 p-0">
              <div className="contact_box contact_box_1">
                <h2 className="title">
                  QUESTIONS ABOUT APPLICATIONS <br />
                  AND THE APPLICATION PROCESS
                </h2>
                <a
                  href="mailto:applications@beautyandyouawards.com"
                  className="redirection"
                >
                  applications@beautyandyouawards.com
                </a>
              </div>
            </div> */}
            {/* <div className="col-lg-6 p-0">
              <div className="contact_box contact_box_2">
                <h2 className="title">
                  Companies interested in supporting BEAUTY&YOU india
                </h2>
                <a
                  href="mailto:media@beautyandyouawards.com"
                  className="redirection"
                >
                  media@beautyandyouawards.com
                </a>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="contact_box contact_box_3">
                <h2 className="title">
                  MEDIA <br />
                  REQUESTS
                </h2>
                <a
                  href="mailto:media@beautyandyouawards.com"
                  className="redirection"
                >
                  media@beautyandyouawards.com
                </a>
              </div>
            </div> */}
            <div className="col-lg-12 p-0">
              <div className="contact_box contact_box_4">
                <h2 className="title">
                  FOR ANY QUESTIONS, <br />
                  PLEASE CONTACT US BELOW:
                </h2>
                <a
                  href="mailto:hello@beautyandyouawards.com"
                  className="redirection"
                >
                  hello@beautyandyouawards.com
                </a>
              </div>
            </div>
          </div>
          <div className="social_wrapper">
            <h3 className="title">connect on social</h3>
            <div className="social_flex">
              <a
                href={instaLink}
                className="social_link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width={23}
                  height={23}
                  src={insta}
                  alt="instagram"
                  className="social_icon"
                  loading="lazy"
                />
              </a>
              <a
                href={linkedinLink}
                className="social_link"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width={23}
                  height={23}
                  src={linke}
                  alt="instagram"
                  className="social_icon"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
